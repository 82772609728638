<template>
  <div>
    <b-table v-if="kind === 'artist'" :data="data">
      <template slot-scope="props">
        <b-table-column field="author" label="Artist" :centered="true">
          <router-link :to="getRouterLink(props.row.id)">
            {{ props.row.author }}
          </router-link>
        </b-table-column>
      </template>
    </b-table>
    <b-table v-if="kind === 'song'" :data="data">
      <template slot-scope="props">
        <b-table-column field="song_id" label="Song chart" :centered="true">
          <router-link :to="getSongChart(props.row.song_id)">
            {{ props.row.name }}
          </router-link>
        </b-table-column>
        <b-table-column field="author" label="Author" :centered="true">
          {{ props.row.author }}
        </b-table-column>
        <b-table-column field="url" label="Tiktok" :centered="true">
          <a :href="toTiktok(props.row.url)" target="_blank">
            tiktok
          </a>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    kind: {
      type: String,
      default: "",
    },
  },
  methods: {
    getRouterLink(id) {
      return `/artist/${id}`;
    },
    getSongChart(id) {
      return `/charts/${id}`;
    },
    toTiktok(url) {
      return "https://www.tiktok.com/music/" + url;
    },
  },
};
</script>

<style></style>
