<template>
  <div class="hero is-medium">
    <div class="hero-body">
      <div class="container box">
        <div class="columns is-centered  container ">
          <div class="column is-centered is-half has-text-centered">
            <span class="title">Search for: </span>
          </div>
        </div>
        <hr />
        <div v-if="result === null">
          <div class="columns is-centered">
            <div class="column is-centered is-half">
              <b-field position="is-centered">
                <b-select v-model="kind" placeholder="What do you want to search?" size="is-medium">
                  <option value="artist">
                    Artist
                  </option>
                  <option value="song">
                    Song
                  </option>
                </b-select>
                <b-input
                  v-model="query"
                  placeholder="Search..."
                  type="search"
                  icon="magnify"
                  size="is-medium"
                />
                <p class="control">
                  <b-button
                    v-if="!loading"
                    type="is-primary is-medium"
                    label="Search"
                    @click="search"
                  />
                  <b-button v-else type="is-primary is-medium" label="Search" loading />
                </p>
              </b-field>
            </div>
          </div>
          <div class="columns is-centered">
            <div class="column is-centered is-half has-text-centered" />
          </div>
        </div>
        <div v-else>
          <search-result :data="result" :kind="kind" />
          <div class="columns is-centered">
            <div class="column is-centered is-half has-text-centered">
              <b-button
                type="is-danger is-medium is-centered"
                label="Clear search"
                @click="result = null"
              />
            </div>
          </div>
        </div>
        <div v-if="empty" class="has-text-centered has-text-danger">
          No results found
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SearchResult from "../components/search/SearchResult.vue";

export default {
  components: { SearchResult },
  data() {
    return {
      kind: "artist",
      query: "",
      loading: false,
      result: null,
      empty: false,
    };
  },
  mounted() {
    // get result for data
    this.$amplitude.logEvent("user start search");
  },
  methods: {
    search() {
      if (this.query === "") {
        return;
      }
      let url = "";
      if (this.kind === "artist") {
        url = `${process.env.VUE_APP_CHART_SERVICE}/api/search/artists?name=${this.query}`;
      }
      if (this.kind === "song") {
        url = `${process.env.VUE_APP_CHART_SERVICE}/api/search/songs/name?name=${this.query}`;
      }
      const k = this.kind;
      this.loading = true;
      this.empty = false;
      axios
        .get(url)
        .then(({ data }) => {
          // api.themoviedb.org manage max 1000 pages
          console.log(data);
          if (k === "song") {
            this.result = data.songs;
          } else {
            this.result = data;
            if (this.result === null) {
              this.empty = true;
            }
          }
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          throw error;
        });
      this.loading = false;
    },
  },
};
</script>

<style></style>
