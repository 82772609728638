<template>
  <div>
    <section v-if="!loading" class="section">
      <div class="columns is-centered ">
        <div class="column has-text-centered">
          <p class="title has-margin-top-10">
            {{ artist.author }}
          </p>
        </div>
      </div>
      <div class="columns is-centered box container has-margin-top-15">
        <div class="column is-half has-text-centered">
          <span class="subtitle">Songs</span>
          <b-table :data="artist.songs">
            <template slot-scope="props">
              <b-table-column field="song_id" label="Song chart" :centered="true">
                <router-link :to="getRouterLink(props.row.song_id)">
                  {{ props.row.name }}
                </router-link>
              </b-table-column>
              <b-table-column field="song_id" label="Song chart" :centered="true">
                <a :href="toTiktok(props.row.url)" target="_blank">
                  tiktok
                </a>
              </b-table-column>
            </template>
          </b-table>
        </div>
      </div>
    </section>
    <b-loading v-else :active.sync="loading" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("chart", ["artist", "loading"]),
  },
  mounted() {
    this.$amplitude.logEvent("display artost Result");
    this.$store.dispatch("chart/getArtist", { id: this.$route.params.id });
  },
  methods: {
    getRouterLink(id) {
      return `/charts/${id}`;
    },
    toTiktok(url) {
      return "https://www.tiktok.com/music/" + url;
    },
  },
};
</script>

<style></style>
