<template>
  <section class="hero is-medium ">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half has-text-centered">
            <artist />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Artist from "../components/artist/Artist.vue";

export default {
  components: { Artist },
  mounted() {
    this.$amplitude.logEvent("Artists dashboard");
  },
};
</script>

<style></style>
